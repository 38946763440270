<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="itemsDetalleActualizacion"
          item-key="rgVarValorLogId"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.valorAnt`]="{ item }">
            <span>{{
              helpers.floatToMoneyString(item.valorAnt, "", true)
            }}</span>
          </template>
          <template v-slot:[`item.valorNuevo`]="{ item }">
            <span>{{
              helpers.floatToMoneyString(item.valorNuevo, "", true)
            }}</span>
          </template>
          <template v-slot:[`item.vigenciaAnterior`]="{ item }">
            <span>{{
              `${item.periodoDesdeAnt} - ${item.periodoHastaAnt}`
            }}</span>
          </template>
          <template v-slot:[`item.nuevaVigencia`]="{ item }">
            <span>{{
              `${item.periodoDesdeNuevo} - ${item.periodoHastaNuevo}`
            }}</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="itemsDetalleActualizacion.length === 0"
          color="primary"
          outlined
          @click="exportExcel()"
          class="to-right fontsize"
        >
          Exportar detalle
        </v-btn>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import helpersExcel from "@/utils/helpers/importExportExcel";
import enums from "@/utils/enums/index.js";
import helpers from "@/utils/helpers.js";

export default {
  name: "DetalleProcesoActualizacionVariables",
  props: {
    procAumVarComId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    search: null,
    searchIcon: enums.icons.SEARCH,
    helpers:helpers,
    formEditTitle: "Detalle del proceso de actualización",
    headers: [
      {
        text: "Variable",
        value: "rgVarNombre",
        sortable: true,
      },
      {
        text: "Valor anterior",
        value: "valorAnt",
        sortable: false,
        align: "end"

      },
      {
        text: "Vigencia anterior",
        sortable: false,
        value: "vigenciaAnterior",
      },
      {
        text: "Nuevo valor",
        sortable: false,
        align: "end",
        value: "valorNuevo",
      },
      {
        text: "Nueva vigencia",
        sortable: false,
        value: "nuevaVigencia",
      },
      
    ],
    itemsDetalleActualizacion: [],
  }),
  created() {
    this.loadDetalleProcesoActualizacion();
  },
  methods: {
    ...mapActions({
      getDetalleProcesoActualizacionVariables:
        "configuracion/getDetalleProcesoActualizacionVariables",
    }),
    async loadDetalleProcesoActualizacion() {
      const response = await this.getDetalleProcesoActualizacionVariables(
        this.procAumVarComId
      );
      this.itemsDetalleActualizacion = response;
    },
    closeModal() {
      this.$emit("toggleModalSeeProcesoActualizacion");
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    exportExcel() {
      let result = [];
      this.itemsDetalleActualizacion.forEach((x) =>
        result.push({
          ["Variable"]: x.rgVarNombre,
          ["Valor anterior"]: x.valorAnt,
          ["Vigencia anterior"]: `${x.periodoDesdeAnt} - ${x.periodoHastaAnt}`,
          ["Nuevo valor"]: x.valorNuevo,
          ["Nueva vigencia"]: `${x.periodoDesdeNuevo} - ${x.periodoHastaNuevo}`,
        })
      );
      let formato = {
        filas: result,
        hoja: "Detalle",
      };
      helpersExcel.excelExport(formato, "Detalle proceso actualización de variables");
    },
  },
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
.fontsize {
  font-size: 12px;
}
</style>
