var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"pt-0"},[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsDetalleActualizacion,"item-key":"rgVarValorLogId","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.valorAnt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.helpers.floatToMoneyString(item.valorAnt, "", true)))])]}},{key:"item.valorNuevo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.helpers.floatToMoneyString(item.valorNuevo, "", true)))])]}},{key:"item.vigenciaAnterior",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.periodoDesdeAnt) + " - " + (item.periodoHastaAnt))))])]}},{key:"item.nuevaVigencia",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.periodoDesdeNuevo) + " - " + (item.periodoHastaNuevo))))])]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"to-right fontsize",attrs:{"disabled":_vm.itemsDetalleActualizacion.length === 0,"color":"primary","outlined":""},on:{"click":function($event){return _vm.exportExcel()}}},[_vm._v(" Exportar detalle ")]),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }