var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"p-0 pt-2",attrs:{"cols":"1"}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}})],1),_c('v-col',{staticClass:"p-0",attrs:{"cols":"11"}},[_c('PageHeader',{attrs:{"title":_vm.title}})],1)],1),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-2",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplyed}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.closeOpenFilters($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{ref:"fechaDesde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                        new Date(
                          _vm.parseDateToIso(_vm.computedFechaDesdeFormatted)
                        ) <=
                          new Date(
                            _vm.parseDateToIso(_vm.computedFechaHastaFormatted)
                          )
                      ) || 'Formato incorrecto',"dense":"","outlined":"","autocomplete":"off","clearable":""},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.computedFechaDesdeFormatted)}},model:{value:(_vm.computedFechaDesdeFormatted),callback:function ($$v) {_vm.computedFechaDesdeFormatted=$$v},expression:"computedFechaDesdeFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.computedFechaDesdeFormatted = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuFechaDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{ref:"fechaHasta",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                        new Date(
                          _vm.parseDateToIso(_vm.computedFechaDesdeFormatted)
                        ) <=
                          new Date(
                            _vm.parseDateToIso(_vm.computedFechaHastaFormatted)
                          )
                      ) || 'Formato incorrecto',"dense":"","outlined":"","autocomplete":"off","clearable":""},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.computedFechaHastaFormatted)}},model:{value:(_vm.computedFechaHastaFormatted),callback:function ($$v) {_vm.computedFechaHastaFormatted=$$v},expression:"computedFechaHastaFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.computedFechaHastaFormatted = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuFechaHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1),_c('v-row',{staticClass:"pr-4 pl-4",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","small":"","disabled":!_vm.isFormValid,"type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.procesosActualizacion,"search":_vm.search,"loading":_vm.loading,"show-expand":"","expanded":_vm.expanded,"item-key":"procAumVarComId"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToNuevoProcesoActualizacion()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
                      var headers = ref.headers;
                      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Fecha alta:")]),_vm._v(" "+_vm._s(item.fecAlta)+" "),_c('strong',{staticStyle:{"padding-left":"80px"}},[_vm._v("Usuario alta:")]),_vm._v(" "+_vm._s(item.usuAlta)+" "),_c('strong',{staticStyle:{"padding-left":"80px"}},[_vm._v("Fecha anulación:")]),_vm._v(" "+_vm._s(item.fecAnula)+" "),_c('strong',{staticStyle:{"padding-left":"80px"}},[_vm._v("Usuario anulación:")]),_vm._v(" "+_vm._s(item.usuAnula)+" ")])]}},{key:"item.anulado",fn:function(ref){
                      var item = ref.item;
return [(item.usuAnula != null)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.usuAnula != null},on:{"click":function($event){return _vm.toggleModalSeeProcesoActualizacion(item.procAumVarComId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.usuAnula != null},on:{"click":function($event){return _vm.anularProceso(item.procAumVarComId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.anularIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Anular proceso")])])]}}],null,true)})],1),(_vm.modalVerDetalleProcesoActualizacion)?_c('v-dialog',{attrs:{"max-width":"60rem","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalSeeProcesoActualizacion($event)}},model:{value:(_vm.modalVerDetalleProcesoActualizacion),callback:function ($$v) {_vm.modalVerDetalleProcesoActualizacion=$$v},expression:"modalVerDetalleProcesoActualizacion"}},[_c('DetalleProcesoActualizacionVariables',{attrs:{"procAumVarComId":_vm.procAumVarComId},on:{"toggleModalSeeProcesoActualizacion":_vm.toggleModalSeeProcesoActualizacion}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"confirmButtonText":'Anular',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmAnularProceso(false)}}}),_c('DeleteDialog',{attrs:{"titleProp":'Atención',"textDelete":_vm.textConfirmAnulacion,"maxWidth":'35rem',"confirmButtonText":'Anular',"openDelete":_vm.showConfirmDeleteModal},on:{"update:openDelete":function($event){_vm.showConfirmDeleteModal=$event},"update:open-delete":function($event){_vm.showConfirmDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmAnularProceso(true)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }