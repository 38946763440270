<template>
  <v-container>
    <v-row>
      <v-col cols="1" class="p-0 pt-2">
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
      </v-col>
      <v-col cols="11" class="p-0">
        <PageHeader :title="title" />
      </v-col>
    </v-row>
    <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
      <v-container>
        <v-row class="pr-4">
          <v-col
            cols="12"
            md="10"
            class="py-0"
            align="left"
            @click="showFilters = true"
          >
            <FiltersSelected :filters="filtersApplyed" v-if="!showFilters" />
          </v-col>
          <v-col
            cols="12"
            md="2"
            align="right"
            align-self="center"
            class="py-0"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  @click.stop="closeOpenFilters"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="applyFilters()"
          >
            <v-row>
              <!-- Fecha desde -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-menu
                  ref="fechaDesde"
                  v-model="menuFechaDesde"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedFechaDesdeFormatted"
                      label="Fecha desde"
                      onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                      :append-icon="calendarIcon"
                      v-mask="'##/##/####'"
                      hint="Formato DD/MM/AAAA"
                      @blur="
                        fechaDesde = parseDateToIso(computedFechaDesdeFormatted)
                      "
                      :rules="
                        rules.required.concat(
                          new Date(
                            parseDateToIso(computedFechaDesdeFormatted)
                          ) <=
                            new Date(
                              parseDateToIso(computedFechaHastaFormatted)
                            )
                        ) || 'Formato incorrecto'
                      "
                      dense
                      outlined
                      autocomplete="off"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaDesde"
                    @change="
                      computedFechaDesdeFormatted = formatDate(fechaDesde)
                    "
                    no-title
                    scrollable
                    @input="menuFechaDesde = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- Fecha hasta -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-menu
                  ref="fechaHasta"
                  v-model="menuFechaHasta"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedFechaHastaFormatted"
                      label="Fecha hasta"
                      onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                      :append-icon="calendarIcon"
                      v-mask="'##/##/####'"
                      hint="Formato DD/MM/AAAA"
                      @blur="
                        fechaHasta = parseDateToIso(computedFechaHastaFormatted)
                      "
                      :rules="
                        rules.required.concat(
                          new Date(
                            parseDateToIso(computedFechaDesdeFormatted)
                          ) <=
                            new Date(
                              parseDateToIso(computedFechaHastaFormatted)
                            )
                        ) || 'Formato incorrecto'
                      "
                      dense
                      outlined
                      autocomplete="off"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaHasta"
                    @change="
                      computedFechaHastaFormatted = formatDate(fechaHasta)
                    "
                    no-title
                    scrollable
                    @input="menuFechaHasta = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="pr-4 pl-4" justify="end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    size="28"
                    @click="resetForm"
                  >
                    {{ clearFiltersIcon }}
                  </v-icon>
                </template>
                <span>Limpiar filtros</span>
              </v-tooltip>
              <v-btn
                color="primary"
                elevation="2"
                small
                :disabled="!isFormValid"
                type="submit"
                form="filters-form"
              >
                Aplicar
              </v-btn>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="procesosActualizacion"
        class="elevation-1"
        :search="search"
        :loading="loading"
        show-expand
        :expanded.sync="expanded"
        item-key="procAumVarComId"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  dense
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4" align="end">
                <v-btn
                  color="primary"
                  class="to-right"
                  @click="goToNuevoProcesoActualizacion()"
                >
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Fecha alta:</strong>
            {{ item.fecAlta }}
            <strong style="padding-left: 80px">Usuario alta:</strong>
            {{ item.usuAlta }}
            <strong style="padding-left: 80px">Fecha anulación:</strong>
            {{ item.fecAnula }}
            <strong style="padding-left: 80px">Usuario anulación:</strong>
            {{ item.usuAnula }}
          </td>
        </template>
        <template v-slot:[`item.anulado`]="{ item }">
          <v-icon color="primary" small v-if="item.usuAnula != null">
            {{ checkIcon }}
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                :disabled="item.usuAnula != null"
                @click="
                  toggleModalSeeProcesoActualizacion(item.procAumVarComId)
                "
              >
                {{ seeIcon }}
              </v-icon>
            </template>
            <span>Ver detalle</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                :disabled="item.usuAnula != null"
                @click="anularProceso(item.procAumVarComId)"
              >
                {{ anularIcon }}
              </v-icon>
            </template>
            <span>Anular proceso</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="modalVerDetalleProcesoActualizacion"
      v-if="modalVerDetalleProcesoActualizacion"
      @keydown.esc="toggleModalSeeProcesoActualizacion"
      max-width="60rem"
      persistent
    >
      <DetalleProcesoActualizacionVariables
        @toggleModalSeeProcesoActualizacion="toggleModalSeeProcesoActualizacion"
        :procAumVarComId="procAumVarComId"
      ></DetalleProcesoActualizacionVariables>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmAnularProceso(false)"
    />
    <DeleteDialog
      :titleProp="'Atención'"
      :textDelete="textConfirmAnulacion"
      :maxWidth="'35rem'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showConfirmDeleteModal"
      @onDeleteItem="confirmAnularProceso(true)"
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import DetalleProcesoActualizacionVariables from "@/views/modules/cuotas/configuracion/DetalleProcesoActualizacionVariables.vue";

export default {
  name: "ProcesosActualizacionVariables",
  directives: { mask },
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    FiltersSelected,
    DetalleProcesoActualizacionVariables
  },
  created() {
    this.setFechaMinusSixMonths();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
   
  },
  data: vm => ({
    routeToGo: "VariablesComerciales",
    title: "Procesos de actualización de variables",
    search: "",
    titleDelete: "¿Desea anular el proceso?",
    expanded: [],
    rules: rules,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    seeIcon: enums.icons.SEE,
    anularIcon: enums.icons.CIRCLE_NONE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    isFormValid: true,
    loading: false,
    calendarIcon: enums.icons.CALENDAR,
    filtersApplyed: [],
    showFilters: true,
    procesosActualizacion: [],
    headers: [
      {
        text: "N° proceso",
        align: "start",
        value: "procAumVarComId",
        sortable: false
      },
      {
        text: "Porcentaje",
        align: "start",
        value: "porcentaje",
        sortable: false
      },
      {
        text: "Variables",
        align: "start",
        value: "filtrosAplicados",
        sortable: false
      },

      {
        text: "Fecha alta",
        align: "start",
        value: "fecAlta",
        sortable: false
      },

      {
        text: "Anulado",
        align: "center",
        value: "anulado",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    showDeleteModal: false,
    idToDelete: null,
    menuFechaHasta: null,
    computedFechaHastaFormatted: vm.formatDate(
      new Date().toISOString().substr(0, 10)
    ),
    fechaHasta: new Date().toISOString().substr(0, 10),
    menuFechaDesde: null,
    computedFechaDesdeFormatted: null,
    fechaDesde: null,
    modalVerDetalleProcesoActualizacion: false,
    procAumVarComId: null,
    showConfirmDeleteModal: false,
    textConfirmAnulacion: "",
  }),
  methods: {
    ...mapActions({
      getProcesosActualizacionVariablesComerciales:
        "configuracion/getProcesosActualizacionVariablesComerciales",
      anulaProcesoActualizacionVariables:
        "configuracion/anulaProcesoActualizacionVariables",
      setAlert: "user/setAlert"
    }),
   
    setFechaMinusSixMonths() {
      // fecha de hoy
      let today = new Date();
      // seis meses atras
      let sixMonths = new Date(today.setMonth(today.getMonth() - 6));
      let sixMonthsAño = sixMonths.getFullYear();
      let sixMonthsMes = sixMonths.getMonth() + 1;
      let sixMonthsDia = sixMonths.getDate();
      sixMonthsDia < 10 ? (sixMonthsDia = `0${sixMonthsDia}`) : sixMonthsDia;
      sixMonthsMes < 10 ? (sixMonthsMes = `0${sixMonthsMes}`) : sixMonthsMes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      this.fechaDesde = `${sixMonthsAño}-${sixMonthsMes}-${sixMonthsDia}`;
      this.computedFechaDesdeFormatted = this.formatDate(this.fechaDesde);
      this.applyFilters();
    },
    goToNuevoProcesoActualizacion() {
      this.$router.push({
        name: "NuevoProcesoActualizacionVariables"
      });
      // this.$store.dispatch("user/changeAppLoading", {
      //   status: true,
      //   text: "",
      //   opaque: true
      // });
    },
    toggleModalSeeProcesoActualizacion(id) {
      this.modalVerDetalleProcesoActualizacion = !this
        .modalVerDetalleProcesoActualizacion;
      this.procAumVarComId = id;
    },
    anularProceso(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmAnularProceso(confirmAnulacion) {
      const payload = {
        id: this.idToDelete,
        confirmAnulacion: confirmAnulacion
      };
      const response = await this.anulaProcesoActualizacionVariables(payload);
      if (response.status === 200) {
        let resultado = response.data.data;
        if (resultado.error == true) {
          this.showDeleteModal = false;
          this.showConfirmDeleteModal = true;
          this.textConfirmAnulacion = resultado.mensaje;
        } else {
          this.showDeleteModal = false;
          this.showConfirmDeleteModal = false;
          this.setAlert({ type: "success", message: "Anulado con éxito." });
          this.closeOpenFilters();
          this.applyFilters();
        }
      }
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    async applyFilters() {
      this.loading = true;
      this.customizeFiltersApplied();
      const data = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta
      };
      try {
        const procesos = await this.getProcesosActualizacionVariablesComerciales(
          data
        );
        this.procesosActualizacion = procesos;
        this.closeOpenFilters();
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      if (this.computedFechaDesdeFormatted) {
        this.filtersApplyed.splice(0, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.computedFechaDesdeFormatted
        });
      }
      if (this.computedFechaHastaFormatted) {
        this.filtersApplyed.splice(1, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.computedFechaHastaFormatted
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    }
  }
};
</script>

<style></style>
